<template>
  <div>
      <el-dialog :title="title"
      :visible.sync="visible"
      :before-close="handleCancel" :close-on-click-modal="close_on"
      @open="instructions"
      >
      <div v-show="!visible2">
        <div v-html="toBreak(txt)" style="color:red;margin-left:10px;font-size:18px">{{ txt }}</div>
      </div>
      <div v-show="visible2">
        <div>
      <form action="">
          第一步：<input type="file" id="file" style="color:red;margin-left:10px"><br><br>
          第二步：<el-button type="success" style="margin-left:10px" @click.prevent="on_sumit">启动机器人</el-button>
      </form>
      </div>
      <span>
      <br>
      </span>
      需要帮助：<el-button type="text" style="margin-left:10px"  @click="downFile()">模板内容下载</el-button>
      </div>
      </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { Message } from 'element-ui'
export default {
  name: 'Operating',
  props: {
    title: String,
    visible: Boolean
  },
  data () {
    return {
      Visible1: false,
      fileId: '',
      headers: {
        Authorization: window.sessionStorage.getItem('token')
      },
      file: 'file',
      fileData: {
        name: ''
      },
      visible2: true,
      txt: '',
      close_on: false

    }
  },
  methods: {
    toBreak (val) {
      if (val) {
        return val.split('。').join('<br/>')
      }
    },
    instructions () {
      if (this.title.includes('使用说明')) {
        this.visible2 = false
        this.close_on = true
        const robot = this.title.split('--使用说明')[0]
        const effect = '使用说明'
        this.axios.post('/Robot_Management/my_robot/', { robot, effect }).then((res) => {
          this.txt = res.data.data[0].instructions
          console.log(res.data.data)
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.visible2 = true
        this.close_on = false
      }
    },
    getNowDate () {
      var date = new Date()
      var sign1 = '-'
      var sign2 = '-'
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1 // 月
      var day = date.getDate() // 日
      var hour = date.getHours() // 时
      var minutes = date.getMinutes() // 分
      var seconds = date.getSeconds() // 秒
      // 给一位数数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (day >= 0 && day <= 9) {
        day = '0' + day
      }
      if (hour >= 0 && hour <= 9) {
        hour = '0' + hour
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = '0' + minutes
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = '0' + seconds
      }
      var currentdate = year + sign1 + month + sign1 + day + '_' + hour + sign2 + minutes + sign2 + seconds
      return currentdate
    },
    downloadFile (url, options = {}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: url,
          data: options,
          responseType: 'blob'
        }).then(
          response => {
            // console.log("下载响应",response)
            resolve(response.data)
            const blob = new Blob([response.data], {
              type: 'application/zip'
            })
            // console.log(blob)
            const fileNameEncode = response.headers['content-disposition'].split('filename=')[1]
            // 解码
            const fileName = decodeURIComponent(fileNameEncode)
            if (window.navigator.msSaveOrOpenBlob) {
              // console.log(2)
              navigator.msSaveBlob(blob, fileName)
            } else {
              // console.log(3)
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = fileName
              link.click()
              // 释放内存
              window.URL.revokeObjectURL(link.href)
            }
          },
          err => {
            reject(err)
          }
        )
      })
    },
    // 下载文件
    downFile () {
      this.$confirm('此操作将下载该机器人模版内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        const postUrl = '/Robot_Management/download/'
        const params = {
          username: localStorage.getItem('user_id'),
          robotname: this.title,
          effect: '模板下载'
        }
        this.downloadFile(postUrl, params)
        this.$message({
          type: 'success',
          message: '模版内容下载成功！'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消模版内容下载！'
        })
      })
    },
    on_sumit () {
      // eslint-disable-next-line camelcase
      var currentdate = this.getNowDate()
      // eslint-disable-next-line camelcase
      const form_data = new FormData() // # 需要添加其他字段时用这一步，只上传文件则不需要
      var file = document.getElementById('file').files[0]
      var username = localStorage.getItem('user_id')
      // eslint-disable-next-line camelcase
      var unit_id = localStorage.getItem('unit_id')
      // eslint-disable-next-line camelcase
      var yun_user = localStorage.getItem('yun_user')
      if (!file.name.includes('.xlsx') && !file.name.includes('.zip')) {
        Message.warning('文件格式不正确，请按照模版要求上传！')
      } else {
        // eslint-disable-next-line camelcase
        form_data.append('file', file, username + '_._' + currentdate + '_._' + this.title + '_._' + unit_id + '_._' + yun_user + '_._' + file.name)
        axios({
          url: '/Robot_Management/upload/',
          method: 'post',
          data: form_data,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          // eslint-disable-next-line no-undef
          Message.success('上传成功')
          this.$emit('cancel', this.Visible1)
        })
      }
    },
    handleCancel () {
      this.$emit('cancel', this.Visible1)
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    }
  }
}
</script>
