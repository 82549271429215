<template>
<div id="fronzen_robot">
      <div class="robot-management">
        <strong style="font-size:13px;width:200px;" >当前机器人为：
          <span style="color:blue;">{{ title }}</span>
        </strong>
        <div class="buttons">
          <el-button type="text"  @click="jiqiren_operating ()" >开始使用</el-button>
          <el-button type="text"  @click="instructions ()" >使用说明</el-button>
          <!-- <div>
            <el-input v-model="robot_pch" placeholder="请查询批次号"  clearable >
            </el-input>
          </div> -->
              <el-date-picker
                v-model="robot_date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                style="width: 230px;"
                value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
          <el-button type="primary" style="height: 30px;margin-top:5px;" @click="select"  size="mini">查询</el-button>
          <el-button type="primary" style="height: 30px;margin-top:5px;" @click="tableRowClassName1" size="mini">重置</el-button>
            <span  style="font-size:16px;margin-top:10px;height: 30px;margin-right: 2px;" >前方排队运行：{{statusnumbers}}</span>
          <el-button type="success" style="height: 30px;margin-top:5px; " size="mini" @click="circlestatus()" >刷新</el-button>
        </div>
    </div>
    <el-divider></el-divider>
    <operating
    :title="title1"
    :visible="visible"
    @cancel="robot_false">
    </operating>
    <div id="robot_ls">
     <el-menu>
      <p id='p_robot' v-show="!show" style="font-size:15px">未绑定机器人</p>
      <el-menu-item v-for="i in robot_list " style="color:black" :key="i" @click="jiqiren_ls(i,pagesize,currentpage - 1)" v-show="show"> {{ i }} </el-menu-item>
    </el-menu>
    </div>
  <div id="shiyong">
  <!-- <el-button type="text"  @click="jiqiren_operating ()" style="line-height: 20px;margin-top:10px;">开始使用</el-button>
  <el-button type="text"  @click="instructions ()" >使用说明</el-button> -->
  <!-- <el-button type="text" @click="exportExcel()">导出数据</el-button> -->
  </div>

  <div id="table">
    <div class="table-container">
  <el-table
  :data="tableData" :row-class-name="tableRowClassName"  :row-style="{height: '0.1'}" :cell-style="{padding: '4px -15px'}"
  >
    <el-table-column prop="id" label="批次号" width="130px" align="center"></el-table-column>
    <el-table-column prop="createdon" label="上传时间"   align="center"></el-table-column>
    <el-table-column prop="executiontime" label="执行时间"  align="center"></el-table-column>
    <el-table-column prop="numbers" label="数量" width="120px" align="center"></el-table-column>
    <el-table-column prop="createdby" label="处理人"   align="center"></el-table-column>
    <el-table-column label="状态" align="center">
        <template slot-scope="scope">
        <p v-if="scope.row.result==0" style="color:#0000ff">执行成功</p>
        <p v-if="scope.row.result==1" style="color:red">执行失败</p>
        <p v-if="scope.row.result==2" style="color:#000000">待执行</p>
        <p v-if="scope.row.result==3" style="color:#a64d79">执行中</p>
        </template>
    </el-table-column>
    <el-table-column  label="数据处理"   width="200" align="center">
    <template slot-scope="scope">
        <el-button @click="original(scope.row,'原始数据')" type="text" size="small">原始数据</el-button>
        <el-button @click="original(scope.row,'结果数据')" type="text" size="small" v-if="scope.row.result==0">结果数据</el-button>
     </template>
    </el-table-column>
  </el-table>
  </div>
  <div style="text-align: right; padding: 30px">
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page.sync="currentpage"
      :page-size="pagesize"
      layout="prev, pager, next, jumper"
      :total="totalcount">
    </el-pagination>
</div>
</div>
</div>
</template>

<script>
import { Message } from 'element-ui'
import operating from './operating.vue'
import axios from 'axios'
import XLSX from 'xlsx'

export default {
  name: 'my_robot',
  data () {
    return {
      // 默认显示第几页
      currentpage: 1,
      // 总条数
      totalcount: 20,
      // 默认每页显示的条数
      pagesize: 10,
      show: true,
      tableData: [],
      robot_list: [],
      currentPage: 1,
      robot_pch: '',
      robot_date: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      statusnumbers: '',
      value1: '',
      value2: '',
      title: '',
      title1: '',
      visible: false,
      user_name: localStorage.getItem('user_id')
    }
  },
  components: {
    operating
  },
  created () {
    this.robotlist()
    this.jiqiren_pd()
  },
  methods: {
    circlestatus () {
      const robot = this.title
      this.jiqiren_ls(robot, this.pagesize, this.currentpage - 1)
      this.jiqiren_pd()
      this.$message({
        type: 'success',
        message: '刷新成功！'
      })
    },
    robotlist () {
      // eslint-disable-next-line camelcase
      const user_id = this.user_name
      const effect = '初始化'
      this.axios.post('/Robot_Management/my_robot/', { user_id, effect }).then((res) => {
        this.robot_list = res.data.data
        const id = this.robot_list[0]
        if (!res.data.res) {
          this.show = false
        } else {
          this.jiqiren_ls(id, this.pagesize, this.currentpage - 1)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    // 显示第几页
    handleCurrentChange (val) {
      console.log('显示第几页', val, val * 10)
      // 改变第几页
      this.currentpage = val
      const robot = this.title
      this.jiqiren_ls(robot, this.pagesize, (val - 1) * 10)
    },
    exportExcel () { // 导出表格
      if (this.title === '') {
        Message.warning('请先选择机器人')
      } else {
        this.$confirm('此操作将导出数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
          const data = XLSX.utils.json_to_sheet(this.tableData)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data, this.title)
          XLSX.writeFile(wb, this.title + '.xlsx')
          this.$message({
            type: 'success',
            message: '导出数据成功！'
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消导出数据！'
          })
        })
      }
    },
    select () {
      if (this.title === '') {
        Message.warning('请先选择机器人')
      } else {
        const robot = this.title
        const effect = '查询'
        this.axios.post('/Robot_Management/my_robot/', {
          robot,
          effect,
          pch: this.robot_pch,
          searchtime: this.robot_date,
          user_id: localStorage.getItem('user_id'),
          unitid: localStorage.getItem('unit_id'),
          rolename: localStorage.getItem('role_name')
        }).then((res) => {
          if (res.data.res === true) {
            this.tableData = res.data.data
          }
        }).catch((error) => {
          console.log(error)
        })
        this.jiqiren_pd()
      }
    },
    jiqiren_ls (id, n1, n2) {
      console.log(id, '111')
      this.title = id
      this.title1 = id
      const robot = id
      const effect = '日志'
      this.axios.post('/Robot_Management/my_robot/', {
        robot,
        effect,
        user_id: localStorage.getItem('user_id'),
        unitid: localStorage.getItem('unit_id'),
        rolename: localStorage.getItem('role_name'),
        limit: n1,
        offset: n2
      }).then((res) => {
        if (res.data.res === true) {
          this.tableData = res.data.data
          this.totalcount = res.data.numbers[0].numbers
        }
      }).catch((error) => {
        console.log(error)
      })
      this.jiqiren_pd()
    },
    jiqiren_pd () {
      const effect = '排队'
      this.axios.post('/Robot_Management/my_robot/', {
        effect
      }).then((res) => {
        if (res.data.res === true) {
          this.statusnumbers = res.data.data[0].statusnumbers
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    jiqiren_operating () {
      if (this.title === '') {
        Message.warning('请先选择机器人')
      } else {
        this.title1 = this.title
        this.visible = true
        this.jiqiren_pd()
      }
    },
    robot_false (ev) { // 控制弹框
      this.visible = ev
      this.tableRowClassName1()
      // console.log(this.visible, this.title)
    },
    instructions () { // 使用说明
      if (this.title === '') {
        Message.warning('请先选择机器人')
      } else {
        this.title1 = this.title + '--使用说明'
        this.visible = true
      }
    },
    downloadFile (url, options = {}) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: url,
          data: options,
          responseType: 'blob'
        }).then(
          response => {
            // console.log("下载响应",response)
            resolve(response.data)
            const blob = new Blob([response.data], {
              type: 'application/vnd.ms-excel'
              // type: 'application/zip'
            })
            const fileNameEncode = response.headers['content-disposition'].split('filename=')[1]
            // 解码
            const fileName1 = decodeURIComponent(fileNameEncode)
            const fileName = fileName1.split('_._')[5]

            if (window.navigator.msSaveOrOpenBlob) {
              // console.log(2)
              navigator.msSaveBlob(blob, fileName)
            } else {
              // console.log(3)
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = fileName
              link.click()
              // 释放内存
              window.URL.revokeObjectURL(link.href)
            }
          },
          err => {
            reject(err)
          }
        )
      })
    },
    // 下载原始数据
    original (scope, info) {
      this.$confirm(info + ' 将被下载到本地, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        const postUrl = '/Robot_Management/download/'
        const params = {
          createdby: scope.createdby,
          robotname: scope.robotname,
          createdon: scope.createdon,
          effect: info
        }
        console.log(params)
        this.downloadFile(postUrl, params)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: info + '  取消导出！'
        })
      })
    },
    handleClick (index, row, rows) {
      // this.editFormVisible = true
      const id = row.id
      const data = 1
      console.log(row)
      // const _this=this
      this.$confirm('此操作将冻结该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post('/robotapi/dongjie/' + id, {
          data
        }).then((res) => {
          console.log(res.data.code)
          if (res.data.code === 1) {
            // _this.$router.push('/index_1/change_unit')
            Message.success('冻结成功')
            // this.handDelete(index, row)
            rows.splice(index, 1)
            // setTimeout(() => { location.reload() }, 800)
          } else {
            Message.warning('冻结失败')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消冻结'
        })
      })
    },
    // tableRowClassName ({ row, rowIndex }) {
    //   if (rowIndex === 0) {
    //     return 'th'
    //   }
    //   return ''
    // },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'success-row'
      }
    },
    // tableRowClassName ({ row, rowIndex }) {
    //   if (rowIndex === 1) {
    //     return 'warning-row'
    //   } else if (rowIndex === 3) {
    //     return 'success-row'
    //   }
    //   return ''
    // },
    switchChange () {
      this.istag = !this.istag
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage
    },
    // 重置按钮
    tableRowClassName1 () {
      this.robot_date = ''
      this.robot_pch = ''
      const robot = this.title
      console.log(robot)
      this.jiqiren_ls(robot, this.pagesize, this.currentpage - 1)
      this.jiqiren_pd()
    },
    querySearchAsync () {
      const robotinfo = this.robot_info
      console.log(this.robot_info)
      if (robotinfo !== '') {
        this.axios.post('/robotapi/robot_info', {
          robotinfo
        }).then((res) => {
          this.tableData = res.data.data
          console.log(res.data.code)
        })
      } else {
        const id = localStorage.use_unit
        this.axios.get('/robotapi/dongjie/' + id, {
        }).then((res) => {
          // if(res.data)
          // Message.success('登陆成功')
          this.tableData = res.data.data
          this.total = this.tableData.length
          console.log(res.data.data)
        }).catch((error) => {
          console.log(error)
        })
      }
    }
  }
}
</script>

<style scoped>

#p_robot {
font-size:20px;
color:rgb(0, 0, 0);
}
#robot_ls {
float: left;
width: 120px;
}
#pch{
float: left;
width: 300px;
margin-left:40px;
}

#block{
float: left;
width: 600px;
margin-left:100px;
}

#shiyong{
float: left;
width: 430px;
/* margin-left:80px; */
}

.el-table .warning-row {
    background: rgb(126, 89, 21);
  }

  .el-table .success-row {
    background: #38850f;
  }
.robot-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.robot-management strong {
  font-size: 2vw;
}
.buttons {
  display: flex;
  gap: 10px;
}
.table-container {
  display: flex;
  flex-direction: column;
  height: 100;
  position: relative;
}
</style>
